import React from "react";
import "./styles.css";

import cta from "../../assets/call_whatsapp2.png";

const CTAWpp2 = () => {
  return (
    <div id="cta2">
      <p>Agende sua Consulta:</p>

      <figure id="ctaWpp">
        <a
          href="http://wa.me/+5565999686554?text=Gostaria%20de%20saber%20mais"
          target="_blank"
          rel="noreferrer"
        >
          <img src={cta} alt="Chamada de ação para Whatsapp" />
        </a>
      </figure>
    </div>
  );
};

export default CTAWpp2;
