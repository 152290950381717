import { useState } from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import logo from "../../assets/logo_topo.png";
import tel from "../../assets/tel.png";
import email from "../../assets/email.png";
import insta from "../../assets/insta.png";
import face from "../../assets/face.png";

const Menu = () => {
  const [active, setActive] = useState(false);

  const ToggleMode = () => {
    setActive(!active);
  };

  return (
    <nav id="menu">
      <section className="container">
        <article
          className={active ? "icon iconActive" : "icon"}
          onClick={ToggleMode}
        >
          <div className="hamburguer hamburguerIcon"></div>
        </article>

        <article className="left">
          <figure>
            <Link to={"/"}>
              <img src={logo} alt="Logo" />
            </Link>
          </figure>
        </article>

        <article className={active ? "right menuOpen" : "right menuClose"}>
          <div className="item">
            <p className="title_menu">fale conosco</p>
            <p className="line_1">
              <img src={tel} alt="Ícone de tel" />
              <a href="tel:999686554">(65) 99968-6554</a>
            </p>
            <p className="line_2">
              <img src={email} alt="Ícone de email" />
              <a href="mailto:odontociron@gmail.com">odontociron@gmail.com</a>
            </p>
          </div>

          <div className="item">
            <p className="title_menu">onde estamos</p>
            <p className="line_unique">
              Rua Presidente Wenceslau Braz, 192 | Cuiabá - MT
            </p>
          </div>

          <div className="item">
            <p className="title_menu">horários</p>
            <p className="line_unique">
              De segunda a sexta, das 07:30 às 17:30h.
            </p>
          </div>

          <div className="item">
            <p className="title_menu">siga-nos</p>
            <p className="line_1">
              <img src={insta} alt="Ícone de insta" />
              <a
                href="http://instagram.com/odontologiacironiekawa"
                target="_blank"
                rel="noreferrer"
              >
                odontologiacironiekawa
              </a>
            </p>
            <p className="line_2">
              <img src={face} alt="Ícone de face" />
              <a
                href="http://facebook.com/profile.php?id=100054366977339"
                target="_blank"
                rel="noreferrer"
              >
                odontologia ciro niekawa
              </a>
            </p>
          </div>
        </article>
      </section>
    </nav>
  );
};

export default Menu;
