import React from "react";
import "./styles.css";

import logo from "../../assets/logo_rodape.png";
import tel from "../../assets/tel.png";
import email from "../../assets/email.png";
import insta from "../../assets/insta.png";
import face from "../../assets/face.png";
import call_gps from "../../assets/call_gps.png";

const Footer = () => {
  return (
    <footer id="footer">
      <section className="footer1">
        <article className="container">
          <figure className="left">
            <img src={logo} alt="Logo" />
          </figure>

          <div className="middle">
            <div className="item">
              <p className="title_menu">fale conosco</p>
              <p className="line_1">
                <img src={tel} alt="Ícone de tel" />
                <a href="tel:999686554">(65) 99968-6554</a>
              </p>
              <p className="line_2">
                <img src={email} alt="Ícone de email" />
                <a href="mailto:odontociron@gmail.com">odontociron@gmail.com</a>
              </p>
            </div>

            <div className="item">
              <p className="title_menu">horários</p>
              <p className="line_unique">
                De segunda a sexta, das 07:30 às 17:30h.
              </p>
            </div>

            <div className="item">
              <p className="title_menu">siga-nos</p>
              <p className="line_1">
                <img src={insta} alt="Ícone de insta" />
                <a
                  href="http://instagram.com/odontologiacironiekawa"
                  target="_blank"
                  rel="noreferrer"
                >
                  odontologiacironiekawa
                </a>
              </p>
              <p className="line_2">
                <img src={face} alt="Ícone de face" />
                <a
                  href="http://facebook.com/profile.php?id=100054366977339"
                  target="_blank"
                  rel="noreferrer"
                >
                  odontologia ciro niekawa
                </a>
              </p>
            </div>
          </div>

          <div className="right">
            <p className="title_menu">onde estamos</p>

            <div className="maprouter">
              <div className="gmap_canvas">
                <iframe
                  className="gmap_iframe"
                  title="endereco"
                  width="100%"
                  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Rua Presidente Wenceslau Braz, 192. Cuiabá - MT&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>

            <figure className="gps">
              <a
                href="https://www.google.com/maps?ll=-15.585376,-56.100431&z=14&t=m&hl=en&gl=US&mapclient=embed&q=R.+Pres.+Wenceslau+Braz,+192+-+Quilombo+Cuiabá+-+MT+78043-505"
                target="_blank"
                rel="noreferrer"
              >
                <img src={call_gps} alt="Acessar Local" />
              </a>
            </figure>
          </div>
        </article>
      </section>

      <section className="footer2">
        <article className="container">
          <p className="text">
            Ciro Niekawa Odontologia - 2023 - Todos os direitos reservados
          </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </article>
      </section>
    </footer>
  );
};

export default Footer;
