import React from "react";
import "./styles.css";

import Form from "../../components/Form";

const Content = () => {
  return (
    <section id="content">
      <div className="container">
        <Form />
      </div>
    </section>
  );
};

export default Content;
