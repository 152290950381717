import React from "react";
import "./styles.css";

import CTAWpp from "../../components/CTAWpp";
import CTAWpp2 from "../../components/CTAWpp2";

import header2 from "../../assets/header2.png";
import bullet from "../../assets/bullet.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h2 className="title">
            <span>Excelência em</span> procedimentos Odontológicos
          </h2>

          <CTAWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <div className="left">
            <h2 className="title">Dr. Ciro Niekawa</h2>
            <p className="text-light">CRO-MT 1063</p>

            <figure>
              <img src={header2} alt="Dr. Ciro sorrindo para foto" />
            </figure>
          </div>

          <div className="right">
            <h2 className="title">Dr. Ciro Niekawa</h2>
            <p className="text-light">CRO-MT 1063</p>

            <ul>
              <li>
                <img src={bullet} alt="Bullet Point" />
                <p>Graduado pela Universidade Estadual de Londrina;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet Point" />
                <p>MBA em Gestão de Saúde;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet Point" />
                <p>Especialista em implantodontia;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet Point" />
                <p>Mestre em Prótese Dental;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet Point" />
                <p>Professor de Pós graduação em Dentística e Prótese;</p>
              </li>
              <li>
                <img src={bullet} alt="Bullet Point" />
                <p>Membro da Sociedade Brasileira de Reabilitação Oral.</p>
              </li>
            </ul>

            <CTAWpp2 />
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
